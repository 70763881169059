<template>
  <div class="factoid">
    <div class="factoid__wrapper">
      <Row justify="between"
           :wrap="$viewport.mobile">
        <div :key="fact.id"
             class="factoid__fact"
             v-for="(fact, index) in facts">
          <Column>
            <div class="factoid__number">{{index + 1}}</div>
            <h5 v-html="fact.title" class="factoid__title"></h5>
            <p class="factoid__description">{{fact.description}}</p>
          </Column>
        </div>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Factoid',
  props: {
    facts: {
      type: Array,
      required: true,
    },
  },
};
</script>
